<template>
  <div class="property-update-edit-wrapper">
    <Permission/>
    <nav class="breadcrumb is-medium mb-6" aria-label="breadcrumbs">
      <ul>
        <li><a @click="toPropertyUpdatesPage" class="previous">Property Updates</a></li>
        <li class="is-active"><a href="#" aria-current="page">Edit</a></li>
      </ul>
    </nav>
    <Form
      @onSave="updatePropertyUpdate"
      :property-update="propertyUpdate"
      :is-saving="isMakingHttpRequest"
      :flash-content="flashContent"
      :hide-flash="hideFlash"
      :editable="canUpdate">
      <b-button v-if="canDelete" @click="deletePropertyUpdate" type="is-danger mr-3" :disabled="isMakingHttpRequest">Delete</b-button>
    </Form>
    <PaperTrailTable v-if="propertyUpdate" :paper-trail="propertyUpdate.paperTrail" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import Permission from '@views/addy_plus/base/Permission.vue'
import Form from './Form.vue'
import PaperTrailTable from '@components/table/paper-trail-table.vue'
import { fetchPropertyUpdate, updatePropertyUpdate, deletePropertyUpdate } from '@api/addy-plus/property-updates'

export default {
  title: 'Property Updates',
  mixins: [pageTitleMixin],
  components: {
    Permission,
    Form,
    PaperTrailTable,
  },
  data() {
    return {
      isMakingHttpRequest: false,
      propertyUpdate: null,
      flashContent: '',
    }
  },
  computed: {
    ...mapState('addyPlusBase', ['permissions']),
    propertyId() {
      return this.$route.params.propertyId
    },
    updateId() {
      return this.$route.params.updateId
    },
    canUpdate() {
      return this.permissions.assetUpdates?.includes('update')
    },
    canDelete() {
      return this.permissions.assetUpdates?.includes('delete')
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    toPropertyUpdatesPage() {
      this.$router.go(-1)
    },
    init() {
      this.isMakingHttpRequest = true
      fetchPropertyUpdate(this.propertyId, this.updateId).then((res) => {
        this.isMakingHttpRequest = false
        if (!res.success) return
        this.propertyUpdate = res.data
      })
    },
    updatePropertyUpdate(formData) {
      this.isMakingHttpRequest = true
      updatePropertyUpdate(this.propertyId, this.updateId, formData).then((res) => {
        this.isMakingHttpRequest = false
        if (!res.success) return
        this.propertyUpdate = res.data
        this.flashContent = 'Successfully save property update'
      })
    },
    deletePropertyUpdate() {
      this.isMakingHttpRequest = true
      deletePropertyUpdate(this.propertyId, this.updateId).then((res) => {
        if (!res.success) return
        this.toPropertyUpdatesPage()
      })
    },
    hideFlash() {
      this.flashContent = ''
    },
  },
}
</script>
<style lang="scss" scoped>
.property-update-edit-wrapper {
  font-size: 16px;
}
</style>
