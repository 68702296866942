<template>
  <b-table :data="paperTrail" hoverable class="mt-5">
    <b-table-column field="who" label="Who" v-slot="props">{{ props.row.who }}</b-table-column>
    <b-table-column field="what" label="What" v-slot="props">
      <li v-for="change in props.row.what" :key="change">
        {{ change }}
      </li>
    </b-table-column>
    <b-table-column field="when" label="When" v-slot="props">{{ props.row.when | formatDate }}</b-table-column>
  </b-table>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  props: {
    paperTrail: {
      type: Array,
      required: true,
    },
  },
  filters: {
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL)
    },
  },
}
</script>
